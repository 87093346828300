import React from 'react';
import './Session.css';

class Session extends React.Component {

    state = { questions: [] }

    renderScore() {
        if (!(this.state.questions.length > 0))
        {
            return;
        }
        let positives = 0;
        this.state.questions.forEach(element => {
            if (element) positives++;
        });
        const scorePercent = Math.trunc( (positives / this.state.questions.length) * 100 );
        return <div className="score">{positives} / {this.state.questions.length}: {scorePercent}% correct</div>;
    }

    render() {
        console.log(this.state);
        return (
            <div className="session">
                {this.renderScore()}
                <div className="vote-buttons">
                    <i className="huge red thumbs down outline icon pointer" onClick={() => this.setState({ questions: [...this.state.questions, false]})}></i>
                    <i className="huge green thumbs up outline icon pointer" onClick={() => this.setState({ questions: [...this.state.questions, true]})}></i>
                </div>
            </div>
        );
    }
};

export default Session;